<template>
    <div class="grid grid-cols-12">
        <div class="col-span-12 font-4xl opacity-85 text-gray-800 font-titillium-bold lg:col-span-6 mt-30 ml-30 mb-20 lg:pl-20">
            {{$t('requestedServicesPageTitlte')}}
        </div>
        <div class="col-span-12 lg:col-span-6 flex lg:items-center lg:justify-end mr-30 lg:pr-20 ml-30 lg:ml-0 mb-30 lg:mb-0 relative">
            <div class="flex items-center mr-20">
                <p class="text-lg2 font-bold font-titillium-bold mr-20 opacity-85">{{$t('requestedServicesTotalNumber')}}</p>
                <span class="totalNumber">{{requestStatistics.total}}</span>
            </div>
            <div class="flex items-center mr-20">
                <p class="text-lg2 font-bold font-titillium-bold mr-20 opacity-85">{{$t('requestedServicesApprovedNumber')}}</p>
                <span class="approvedNumber">{{requestStatistics.total_approved}}</span>
            </div>
            <div class="flex items-center mr-20">
                <p class="text-lg2 font-bold font-titillium-bold mr-20 opacity-85">{{$t('requestedServicesDeclinedNumber')}}</p>
                <span class="declinedNumber">{{requestStatistics.total_rejected}}</span>
            </div>
            <!-- Confirmation box for success messages -->
            <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
            </div>
        </div>
        <div
            class="col-span-12 mx-30 bg-white rounded-lg shadow"
            :class="[allRequestedServices.length ? 'pb-30' : 'pb-0']"
        >
            <v-table
                v-if="requestedServicesLoading.length !== 0"
                class="table"
                :pageSize="pageSize"
                :hideSortIcons="true"
                :data="allRequestedServices"
                :currentPage.sync="currentPage"
                @totalPagesChanged="totalPages = $event"
            >
                <tbody
                    id="table_body"
                    class="tbody"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <tr
                        class="border-b"
                        v-for="row in displayData"
                        :key="row.id"
                    >
                        <td class="lg:w-2/5">
                            <p class="font-2xl2 mb-10">{{row.service.name}}</p>
                            <p class="text-lg2 font-bold font-titillium-normal mb-10">{{row.ngo.name}}</p>
                            <p class="font-titillium-normal mb-22">{{row.service.description}}</p>
                            <div class="flex items-center">
                                <img
                                    :src="require('@/assets/images/icons/blue_phone.svg')"
                                    alt="phone-icon"
                                    width="20"
                                />
                                <a
                                    :href="'tel:' + row.ngo.phone"
                                    class="p font-bold text-sm ml-12 mr-22 text-purple-600"
                                >{{$t('requestedServicesServicePhoneText')}}</a>
                                <img
                                    :src="require('@/assets/images/icons/email.svg')"
                                    alt="email-icon"
                                />
                                <a
                                    :href="'mailto:' + row.ngo.email"
                                    class="p font-bold text-sm ml-12 text-purple-600"
                                >{{$t('requestedServicesServiceEmailText')}}</a>
                            </div>
                        </td>
                        <td class="pr-20 lg:w-3/12">
                            <div class="flex flex-col lg:justify-center lg:items-center">
                                <p class="font-4xl">{{row.amount}} {{row.service.measurement_unit}}</p>
                                <p class="text-gray-500">{{$t('requestedServiceAmount')}}</p>
                            </div>
                        </td>
                        <td class="lg:w-2/6">
                            <div class="flex lg:flex-col xl:flex-row xl:mr-20">
                                <Button
                                    @click.native="approveRequest(row)"
                                    v-if="row.status === 'requested'"
                                    class="button w-132 rounded-lg bg-emerald-500 text-white hover:bg-emerald-600 sm:mr-20"
                                    role="button"
                                    :text="$t('requestedServiceAcceptButtonText')"
                                    :disabled="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].approveRequestLoading"
                                    :spinning="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].approveRequestLoading"
                                />
                                <p
                                    v-if="row.status === 'approved'"
                                    class="flex items-center font-lg-bold text-emerald-500 py-8 px-20 xl:mr-20"
                                >{{$t('requestedServiceAcceptedText')}}</p>
                                <Button
                                    @click.native="reapproveRequest(row)"
                                    v-if="row.status === 'canceled' || row.status === 'rejected'"
                                    class="button w-132 rounded-lg bg-emerald-500 text-white hover:bg-emerald-600 xl:mr-20"
                                    role="button"
                                    :text="$t('requestedServiceReacceptButtonText')"
                                    :disabled="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].reapproveRequestLoading"
                                    :spinning="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].reapproveRequestLoading"
                                />
                                <p
                                    v-if="row.status === 'reapproved'"
                                    class="flex items-center font-lg-bold text-emerald-500 px-20 py-8 xl:mr-20"
                                >{{$t('requestedServiceReacceptedText')}}</p>
                                <Button
                                    @click.native="rejectRequest(row)"
                                    v-if="row.status === 'requested'"
                                    class="button rounded-lg w-132 bg-red-500 text-white hover:bg-red-600 xl:mt-0 lg:mt-20"
                                    role="button"
                                    :text="$t('requestedServiceDeclineButtonText')"
                                    :disabled="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].rejectRequestLoading"
                                    :spinning="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].rejectRequestLoading"
                                />
                                <p
                                    v-if="row.status === 'rejected'"
                                    class="flex items-center font-lg-bold text-red-500 px-20 py-8"
                                >{{$t('requestedServiceDeclinedText')}}</p>
                                <Button
                                    @click.native="cancelRequest(row)"
                                    v-if="row.status === 'approved' || row.status === 'reapproved'"
                                    class="button rounded-lg w-132 bg-red-500 text-white hover:bg-red-600"
                                    role="button"
                                    :text="$t('requestedServiceCancelButtonText')"
                                    :disabled="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].cancelRequestLoading"
                                    :spinning="requestedServicesLoading[requestedServicesLoading.findIndex(x => { return x.id === row.id })].cancelRequestLoading"
                                />
                                <p
                                    v-if="row.status === 'canceled'"
                                    class="flex items-center font-lg-bold text-red-500 px-20 py-8"
                                >{{$t('requestedServiceCancelledText')}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-if="displayData.length === 0"
                    >
                        <td class="font-titillium-normal text-gray-800">
                            {{$t('noServicesText')}}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-table
                v-else
                class="table"
                :data="allRequestedServices"
            >
                <tbody
                    class="tbody grid grid-cols-12"
                    slot="body"
                >
                    <tr
                        class="col-span-12"
                    >
                        <td class="font-titillium-normal text-gray-800">
                            {{$t('noRequestsText')}}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <div v-if="allRequestedServices.length" class="flex items-center justify-between mt-30">
                <div class="pl-10 p text-gray-800">{{page}}-{{pageSizeIncrement}} {{ $t('fromText') }} {{allRequestedServices.length}} {{allRequestedServices.length > 1 ? $t('fromRequestsText') : $t('fromRequestsTextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage"
                        :totalPages="totalPages"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/buttons/Button'
import SuccessBox from '@/components/success-message/SuccessBox'

export default {
    name: 'ServiceRequests',
    components: {
        Button
    },
    data () {
        return {
            currentPage: 1,
            totalPages: 0,
            confirmationBox: false,
            approvedMessage: false,
            reapprovedMessage: false,
            rejectedMessage: false,
            cancelledMessage: false,
            pageSize: 8,
            pageSizeIncrement: null,
            currentPageSize: null,
            page: null,

            requestedServicesLoading: []
        }
    },
    computed: {
        ...mapGetters(['allRequestedServices', 'errors', 'error', 'token', 'updateRequestStatus', 'requestStatistics'])
    },
    methods: {
        ...mapActions(['getRequestedServices', 'updateRequest', 'getRequestStatistics']),
        /** Approve a request function */
        approveRequest (row) {
            this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].approveRequestLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const requestToUpdate = {
                id: row.id,
                amount: row.amount,
                message: row.message,
                ngo: row.ngo,
                service: row.service,
                status: 'approved'
            }

            this.updateRequest({ token: this.token, request: requestToUpdate })
                .then(response => {
                    if (this.updateRequestStatus === true) {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].approveRequestLoading = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('requestedServiceApprovedTextBox'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].approveRequestLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Reapprove a request function */
        reapproveRequest (row) {
            this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].reapproveRequestLoading = true

            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const requestToUpdate = {
                id: row.id,
                amount: row.amount,
                message: row.message,
                ngo: row.ngo,
                service: row.service,
                status: 'reapproved'
            }

            this.updateRequest({ token: this.token, request: requestToUpdate })
                .then(response => {
                    if (this.updateRequestStatus === true) {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].reapproveRequestLoading = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('requestedServiceReapprovedTextBox'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].reapproveRequestLoading = false

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Reject a request function */
        rejectRequest (row) {
            this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].rejectRequestLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const requestToUpdate = {
                id: row.id,
                amount: row.amount,
                message: row.message,
                ngo: row.ngo,
                service: row.service,
                status: 'rejected'
            }

            this.updateRequest({ token: this.token, request: requestToUpdate })
                .then(response => {
                    if (this.updateRequestStatus === true) {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].rejectRequestLoading = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('requestedServiceRejectedTextBox'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].rejectRequestLoading = false

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Cancel a request function */
        cancelRequest (row) {
            this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].cancelRequestLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const requestToUpdate = {
                id: row.id,
                amount: row.amount,
                message: row.message,
                ngo: row.ngo,
                service: row.service,
                status: 'canceled'
            }

            this.updateRequest({ token: this.token, request: requestToUpdate })
                .then(response => {
                    if (this.updateRequestStatus === true) {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].cancelRequestLoading = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('requestedServiceCancelledTextBox'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.requestedServicesLoading[this.requestedServicesLoading.findIndex(x => { return x.id === row.id })].cancelRequestLoading = false

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Display info table in table footer method */
        getTableRecordsInfo () {
            this.currentPageSize = document.getElementById('table_body')?.childElementCount
            this.page = (this.pageSize * (this.currentPage - 1)) + 1

            if (this.allRequestedServices.length < (this.pageSize * this.currentPage)) {
                this.pageSizeIncrement = (this.pageSize * (this.currentPage - 1)) + this.currentPageSize
            } else {
                this.pageSizeIncrement = (this.pageSize * this.currentPage)
            }
        }
    },
    updated () {
        this.getTableRecordsInfo()
    },
    async created () {
        /** Get requsted services and the statistics for them. */
        await this.getRequestedServices({ token: this.token }).then(() => {
            for (const service in this.allRequestedServices) {
                this.requestedServicesLoading.push({
                    id: this.allRequestedServices[service].id,
                    approveRequestLoading: false,
                    reapproveRequestLoading: false,
                    rejectRequestLoading: false,
                    cancelRequestLoading: false
                })
            }

            this.getTableRecordsInfo()
        })
        await this.getRequestStatistics({ token: this.token })
    }
}
</script>
